export default function useSuccessToaster() {
  const toaster = useToaster()
  const { t } = useI18n()

  const toast = ({ title, message }: { title?: string, message: string }) => {
    toaster.clearAll()
    toaster.show({
      title: title || t('success'),
      message: message,
      color: 'success',
      icon: 'ph:check',
      closable: true,
    })
  }

  return { toast }
}
